import styles from "./../styles/terms.module.scss"
import menuStyles from "./../styles/commons/terms-privacy-menu.module.scss"
import React, { useContext, useRef } from "react"
import Layout from "../components/layout/layout"
import {
  TERMS_ACCESS_AND_USE,
  TERMS_AVAILABILITY,
  TERMS_CHANGES_TO_SITE,
  TERMS_DISCLAIMER,
  TERMS_DISCLAIMERS,
  TERMS_FEEDBACK,
  TERMS_GENERAL,
  TERMS_GOVERNING_LAW,
  TERMS_INDEMNIFICATION,
  TERMS_LIMITATION,
  TERMS_PRIVACY,
  TERMS_PROHIBITED_USE,
  TERMS_QUESTIONS,
  TERMS_TERMINATION,
  TERMS_THIRD_PARTY,
  PRIVACY_POLICY_PAGE_URL,
} from "../utils/constants"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../contexts/app.context"
import { ShowAnnouncementContext } from "../contexts/show-announcement.context"

const TermsPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: { relativePath: { eq: "terms" }, category: { eq: "seo" } }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  const refs = {
    [TERMS_CHANGES_TO_SITE]: useRef(),
    [TERMS_ACCESS_AND_USE]: useRef(),
    [TERMS_PROHIBITED_USE]: useRef(),
    [TERMS_FEEDBACK]: useRef(),
    [TERMS_TERMINATION]: useRef(),
    [TERMS_THIRD_PARTY]: useRef(),
    [TERMS_AVAILABILITY]: useRef(),
    [TERMS_PRIVACY]: useRef(),
    [TERMS_DISCLAIMER]: useRef(),
    [TERMS_LIMITATION]: useRef(),
    [TERMS_DISCLAIMERS]: useRef(),
    [TERMS_INDEMNIFICATION]: useRef(),
    [TERMS_GOVERNING_LAW]: useRef(),
    [TERMS_GENERAL]: useRef(),
    [TERMS_QUESTIONS]: useRef(),
  }

  const scrollToSection = section => {
    refs[section].current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/terms"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />
      <div className={styles.terms}>
        <div className={`container volterra-container`}>
          <div className="row">
            <div
              className={`col-12 col-lg-4 ${styles.terms__menu} ${menuStyles.menu}`}>
              <h5 className={menuStyles.menu__title}>Terms of Use</h5>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_CHANGES_TO_SITE)
                }}>
                1. CHANGES TO THE SITE AND CONTENT; MODIFICATION OF THESE TERMS
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_ACCESS_AND_USE)
                }}>
                2. ACCESS AND USE OF SITE AND CONTENT
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_PROHIBITED_USE)
                }}>
                3. PROHIBITED USE
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_FEEDBACK)
                }}>
                4. FEEDBACK
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_TERMINATION)
                }}>
                5. TERMINATION
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_THIRD_PARTY)
                }}>
                6. LINKS TO THIRD PARTY SITES
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_AVAILABILITY)
                }}>
                7. AVAILABILITY OF THE SITE
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_PRIVACY)
                }}>
                8. PRIVACY AND PERSONAL INFORMATION
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_DISCLAIMER)
                }}>
                9. DISCLAIMER
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_LIMITATION)
                }}>
                10. LIMITATION OF LIABILITY
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_DISCLAIMERS)
                }}>
                11. APPLICATION OF DISCLAIMERS AND LIMITATIONS
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_INDEMNIFICATION)
                }}>
                12. INDEMNIFICATION
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_GOVERNING_LAW)
                }}>
                13. GOVERNING LAW AND DISPUTES
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_GENERAL)
                }}>
                14. GENERAL
              </p>
              <p
                className={menuStyles.menu__link}
                onClick={() => {
                  scrollToSection(TERMS_QUESTIONS)
                }}>
                15. QUESTIONS
              </p>
            </div>
            <div className={`col-12 col-lg-8 ${styles.terms__body}`}>
              <h2 className={styles.terms__title}>
                VOLTERRA, INC. WEBSITE TERMS OF USE
              </h2>
              <p className={styles.terms__date}>
                Last Updated: November 4, 2019
              </p>

              <p className={styles.terms__text}>
                These terms of use (the “<b>Terms</b>”) are a legal contract
                between you (“<b>you</b>” and “<b>your</b>”) and Volterra, Inc.
                (“<b>Volterra,</b>“ “<b>we,</b>” “<b>us</b>,” or “<b>our</b>”)
                and govern your access to, and use of, the Volterra’s public
                facing corporate website located at{" "}
                <a
                  aria-label="Open www.volterra.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.terms__link}
                  href="https://www.volterra.io">
                  www.volterra.io
                </a>{" "}
                (the “<b>Site</b>”) and Content (as defined below in Section 2).
              </p>
              <p className={styles.terms__text}>
                For the avoidance of doubt, unless the use of any products
                and/or services of Volterra are expressly subject to these Terms
                (for example, through an authorized link at the time you obtain
                such products and/or services), these Terms do not apply to any
                products or services provided by Volterra. The use of any
                Volterra products and/or services shall be subject to the
                applicable end user agreement entered into between you and
                Volterra for the applicable product and/or service.
              </p>
              <p className={styles.terms__text}>
                PLEASE NOTE, THESE TERMS CONTAIN AN AGREEMENT TO ARBITRATE WHICH
                WILL REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING
                AND FINAL ARBITRATION AND A WAIVER OF YOUR RIGHT TO PARTICIPATE
                IN CLASS ACTIONS AS SET FORTH, AND SUBJECT TO, SECTION 13. IN
                ADDITION, PLEASE BE ADVISED THAT VOLTERRA DOES NOT PROVIDE
                WARRANTIES FOR THE SITE AND/OR CONTENT AND THESE TERMS LIMIT OUR
                LIABILITY TO YOU. PLEASE SEE SECTIONS 9 AND 10 FOR FURTHER
                INFORMATION.
              </p>
              <p className={styles.terms__text}>
                BY ACCESSING AND/OR USING THE SITE AND/OR CONTENT, YOU
                ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE
                BOUND BY THESE TERMS. IF YOU DO NOT AGREE WITH ANY OF THESE
                TERMS, DO NOT ACCESS OR OTHERWISE USE THE SITE OR ANY
                INFORMATION CONTAINED ON THE SITE.
              </p>
              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_CHANGES_TO_SITE]}
                id="changes_to_the_site">
                <h2 className={styles.terms__title}>
                  1. CHANGES TO THE SITE AND CONTENT; MODIFICATION OF THESE
                  TERMS
                </h2>
                <p className={styles.terms__text}>
                  VOLTERRA MAY MAKE CHANGES TO, AND/OR DISCONTINUE OR SUSPEND,
                  THE AND/OR CONTENT AT ANY TIME WITHOUT NOTICE. YOU AGREE THAT
                  VOLTERRA WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR
                  ANY CHANGES, SUSPENSION OR DISCONTINUANCE OF THE SITE AND/OR
                  CONTENT (OR ANY PART THEREOF).
                </p>
                <p className={styles.terms__text}>
                  VOLTERRA RESERVES THE RIGHT TO CHANGE OR MODIFY THESE TERMS AT
                  ANY TIME. IF VOLTERRA MAKES ANY SUCH CHANGES, VOLTERRA WILL
                  POST UPDATED TERMS OF USE ON THIS SITE. IF WE MAKE ANY
                  MATERIAL CHANGES TO THESE TERMS, WE WILL NOTIFY YOU BY POSTING
                  A NOTICE OF THE CHANGES ON THE SITE. IT IS YOUR RESPONSIBILITY
                  TO REGULARLY VISIT AND REVIEW THESE TERMS. IF ANY MODIFICATION
                  IS UNACCEPTABLE TO YOU, YOUR SOLE REMEDY SHALL BE TO CEASE
                  USING THE SITE. IF YOU DO NOT CEASE USING THE SITE AND/OR
                  CONTENT FOLLOWING THE DATE WE POST THE UPDATED TERMS, YOU WILL
                  BE DEEMED TO HAVE ACCEPTED THE CHANGE.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_ACCESS_AND_USE]}
                id="access_and_use">
                <h2 className={styles.terms__title}>
                  2. ACCESS AND USE OF SITE AND CONTENT
                </h2>
                <p className={styles.terms__text}>
                  Subject to your compliance with these Terms, Volterra grants
                  you a limited, revocable, nonexclusive right to access and use
                  the Site and Content solely for your own personal
                  non-commercial use.
                </p>
                <p className={styles.terms__text}>
                  Volterra and its licensors retain all right, title and
                  interest in and to the Site, including, without limitation,
                  any and all text, graphics, images, music, software, audio,
                  video, works of authorship of any kind, and information or
                  other materials that are posted, generated, provided or
                  otherwise made available on the Site (collectively, the “
                  <b>Content</b>”). All Volterra trademarks are strictly owned
                  by Volterra, and nothing in these Terms will be construed to
                  transfer ownership rights or grant any permission, license or
                  other rights to any Volterra trademark without written
                  authorization from Volterra. The names of actual companies and
                  products mentioned within the Site and/or Content may be the
                  trademarks of their respective owners. Volterra reserves all
                  rights and licenses not expressly granted to you in these
                  Terms and no implied license is granted by Volterra. The
                  Content and Site, and its underlying technology, are protected
                  by copyright, trademark, patent, intellectual property, and
                  other laws of the United States and foreign countries. You
                  agree not to remove, change or obscure any copyright,
                  trademark, service mark or other proprietary rights notices
                  incorporated in or accompanying the Site and/or any Content.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_PROHIBITED_USE]}
                id="prohibited_use">
                <h2 className={styles.terms__title}>3. PROHIBITED USE</h2>
                <p className={styles.terms__text}>
                  As a condition of your use of the Site and Content, you
                  warrant to Volterra that you will not use the Site and/or
                  Content for any purpose that is unlawful or prohibited by
                  these Terms. Specifically, You are not allowed to (directly or
                  indirectly): (a) resell or otherwise make commercial use of
                  the Site and/or Content; (b) collect or use any images,
                  descriptions, or other content included in the Site and/or
                  Content, or any portion thereof; (c) copy, imitate,
                  distribute, publicly perform, or publicly display Site and/or
                  Content; (d) modify or otherwise make any derivative uses of
                  the Site and/or Content, or any portion thereof; (e) use data
                  mining, robots or similar data gathering or extraction methods
                  on the Site; (f) perform, or release or disclose the results
                  of, any benchmark testing or vulnerability assessments of the
                  Site; (g) introduce into the Site any viruses, trojan horses,
                  malware, spyware, adware or other disruptive software, or any
                  software code, which is designed to disrupt, damage, or
                  perform unauthorized actions on a computer system; (h) remove
                  or alter any proprietary notices or labels on or in the Site
                  and/or Content; (i) use the Site and/or Content to directly or
                  indirectly develop any product or service that competes with
                  the Site; (j) download (other than page caching) any portion
                  of the Site and/or Content or any information contained
                  therein, except as expressly permitted on the Site; or (k) use
                  the Site and/or Content other than as expressly permitted in
                  these Terms.
                </p>
                <p className={styles.terms__text}>
                  You may not use the Site and/or Content in any manner that
                  would violate any applicable laws, rules or regulations, or,
                  which could damage, disable, overburden, or impair the Site or
                  interfere with any other party's use and enjoyment of the Site
                  and/or Content. You may not obtain or attempt to obtain any
                  materials or information through any means not intentionally
                  made available or provided for through the Site, including,
                  but not limited to, data mining, robots, or similar data
                  gathering and extraction tools.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_FEEDBACK]}
                id="feedback">
                <h2 className={styles.terms__title}>4. FEEDBACK</h2>
                <p className={styles.terms__text}>
                  Volterra shall be entitled to unrestricted use of any and all
                  comments, suggestions, ideas, notes, drawings, concepts,
                  problems or other information with respect to the Site and/or
                  Content disclosed or offered by you to us (collectively, “
                  <b>Feedback</b>”). We may use the Feedback for any and all
                  purposes whatsoever, commercial or otherwise, without any
                  payment or other obligation to you or any other person
                  involved with the creation of the Feedback.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_TERMINATION]}
                id="termination">
                <h2 className={styles.terms__title}>5. TERMINATION</h2>
                <p className={styles.terms__text}>
                  Volterra reserves the right, in its sole discretion, to
                  terminate and/or suspend your access to the Site and/or
                  Content or any portion thereof at any time, for any reason,
                  without notice.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_THIRD_PARTY]}
                id="third_party">
                <h2 className={styles.terms__title}>
                  6. LINKS TO THIRD PARTY SITES
                </h2>
                <p className={styles.terms__text}>
                  The Site may contain links to other third party websites which
                  are not controlled or owned by Volterra (“
                  <b>Linked Sites</b>”). If you decide to access and use such
                  Linked Sites, be advised that your use is governed solely by
                  the terms and conditions of such Linked Sites, and Volterra
                  does not endorse, is not responsible for, and make no
                  representations as to such Linked Sites, their content or the
                  manner in which they handle your data. Volterra is not liable
                  for any damage or loss caused or alleged to be caused by or in
                  connection with your access or use of any such Linked Sites,
                  or your reliance on the privacy practices or other policies of
                  such Linked Sites.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_AVAILABILITY]}
                id="availability">
                <h2 className={styles.terms__title}>
                  7. AVAILABILITY OF THE SITE
                </h2>
                <p className={styles.terms__text}>
                  Volterra makes no claims regarding the availability or right
                  to access or use of the Site and/or Content outside of the
                  United States. Volterra may restrict access to the Site and/or
                  Content, or portions thereof, in certain countries in its sole
                  discretion, and the Site and/or Content may not be available
                  or accessible in all languages. If you use or access the Site
                  and/or Content outside of the United States, you are
                  responsible for compliance with the laws and regulations of
                  your jurisdiction with respect to your use of the Site and/or
                  Content in your country of residence.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_PRIVACY]}
                id="privacy_and_personal_information">
                <h2 className={styles.terms__title}>
                  8. PRIVACY AND PERSONAL INFORMATION
                </h2>
                <p className={styles.terms__text}>
                  For information about Volterra data protection practices,
                  please read the{" "}
                  <a
                    aria-label="Open Privacy Policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={PRIVACY_POLICY_PAGE_URL}>
                    privacy policy
                  </a>
                  . This policy explains how Volterra treats your personal
                  information, and protects your privacy, when you provide
                  personally identifiable information to Volterra in connection
                  with your use of the Site.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_DISCLAIMER]}
                id="disclaimer">
                <h2 className={styles.terms__title}>9. DISCLAIMER</h2>
                <p className={styles.terms__text}>
                  THE SITE AND CONTENT (AND ANY PART THEREOF), AND ANY OTHER
                  MATERIALS OR INFORMATION MADE AVAILABLE THROUGH THE SITE, ARE
                  PROVIDED "AS IS" AND "AS AVAILABLE". VOLTERRA AND ITS
                  LICENSORS AND SUPPLIERS HEREBY EXPRESSLY DISCLAIM ANY
                  REPRESENTATIONS, WARRANTIES OR GUARANTEES OF ANY KIND, EXPRESS
                  OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS
                  FOR ANY PARTICULAR PURPOSE, SATISFACTORY PURPOSE, ACCURACY, OR
                  NON-INFRINGEMENT.
                </p>
                <p className={styles.terms__text}>
                  VOLTERRA AND ITS LICENSORS AND SUPPLIERS DO NOT WARRANT OR
                  MAKE ANY GUARANTEE THAT SITE AND/OR CONTENT (OR ANY PART
                  THEREOF), OR ANY OTHER MATERIALS OR INFORMATION MADE AVAILABLE
                  THROUGH THE SITE: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL
                  BE COMPATIBLE WITH YOUR HOME NETWORK, COMPUTER OR MOBILE
                  DEVICE, OR ANY THIRD PARTY SITES; (III) WILL BE AVAILABLE ON
                  AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE BASIS; OR (IV)
                  WILL BE ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION,
                  WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM VOLTERRA OR ANY
                  THIRD PARTY, SHALL CREATE ANY WARRANTY.
                </p>
                <p className={styles.terms__text}>
                  VOLTERRA DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME
                  RESPONSIBILITY FOR ANY LINKED SITES, ADVERTISED OR ACCESSIBLE
                  THROUGH THE SITE AND/OR CONTENT, AND VOLTERRA WILL NOT BE A
                  PARTY TO, OR IN ANY WAY MONITOR, ANY TRANSACTION BETWEEN YOU
                  AND THE THIRD-PARTY OPERATORS OF SUCH LINKED SITES.
                </p>
                <p className={styles.terms__text}>
                  IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE AND/OR
                  CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                  USING THE SITE.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_LIMITATION]}
                id="limitation">
                <h2 className={styles.terms__title}>
                  10. LIMITATION OF LIABILITY
                </h2>
                <p className={styles.terms__text}>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL VOLTERRA BE LIABLE FOR ANY INDIRECT, PUNITIVE,
                  INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                  WHATSOEVER, OR FOR ANY DAMAGES FOR LOSS OF USE, DATA, OR
                  PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
                  OR PERFORMANCE OF THE SITE AND/OR CONTENT, WITH THE DELAY OR
                  INABILITY TO USE THE SITE AND/OR CONTENT, OR FOR ANY
                  INFORMATION OBTAINED THROUGH THE SITE AND/OR CONTENT, OR
                  OTHERWISE ARISING OUT OF THE USE OF THE SITE AND/OR CONTENT,
                  WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
                  OR OTHERWISE, EVEN IF VOLTERRA HAS BEEN ADVISED OF THE
                  POSSIBILITY OF DAMAGES. IN NO EVENT SHALL VOLTERRA’S TOTAL
                  CUMULATIVE LIABILITY ARISING FROM OR RELATED TO THESE TERMS OR
                  THE ACCESS, USE OF, OR INABILITY TO ACCESS OR USE THE SITE
                  AND/OR CONTENT (OR ANY PART THEREOF), AND/OR ANY OR ANY OTHER
                  MATERIALS OR INFORMATION MADE AVAILABLE THROUGH THE SITE,
                  EXCEED FIVE HUNDRED DOLLARS ($500.00). VOLTERRA DISCLAIMS ALL
                  LIABILITY OF ANY KIND OF VOLTERRA’S LICENSORS AND SUPPLIERS.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_DISCLAIMERS]}
                id="application_of_disclaimers">
                <h2 className={styles.terms__title}>
                  11. APPLICATION OF DISCLAIMERS AND LIMITATIONS
                </h2>
                <p className={styles.terms__text}>
                  BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE DISCLAIMERS
                  OF CERTAIN WARRANTIES AND/OR THE EXCLUSION OR LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL, SPECIAL, INCIDENTAL OR OTHER
                  DAMAGES, THE ABOVE DISCLAIMERS AND LIMITATIONS SET FORTH IN
                  SECTIONS 9 AND 10 MAY NOT APPLY TO YOU. IN SUCH EVENT, THE
                  LIABILITY OF VOLTERRA FOR SUCH DAMAGES WILL BE LIMITED TO THE
                  GREATEST EXTENT PERMITTED BY APPLICABLE LAW IN SUCH
                  JURISDICTION.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_INDEMNIFICATION]}
                id="indemnification">
                <h2 className={styles.terms__title}>12. INDEMNIFICATION</h2>
                <p className={styles.terms__text}>
                  You agree that you are solely responsible for (and that
                  Volterra has no responsibility to you or to any third party
                  for) any breach of your obligations under the Terms and for
                  the consequences (including any loss or damage which Volterra
                  may suffer) of any such breach. Except as prohibited by law,
                  you will hold Volterra and its officers, directors, employees
                  and agents harmless for any indirect, punitive, special,
                  incidental or consequential damage, however it arises
                  (including attorneys' fees and all related costs and expenses
                  of litigation and arbitration, or at trial or on appeal, if
                  any, whether or not litigation or arbitration is instituted),
                  whether in an action of contract, negligence or other tortious
                  action, or arising out of or in connection with these Terms,
                  including without limitation any claim for personal injury or
                  property damage, arising from (i) your use of and access to
                  the Site and/or Content; and (ii) your violation of any of
                  these Terms. We reserve the right to assume control of the
                  defense of any third-party claim that is subject to
                  indemnification by you, in which event you will cooperate with
                  us in asserting any available defenses. This defense and
                  indemnification obligation will survive these Terms and your
                  use of the Services.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_GOVERNING_LAW]}
                id="governing_law">
                <h2 className={styles.terms__title}>
                  13. GOVERNING LAW AND DISPUTES
                </h2>
                <p className={styles.terms__text}>
                  <u>Governing Law</u>. These Terms will be governed by the laws
                  of the State of California, United States of America without
                  giving effect to any conflict of laws principles.
                </p>
                <p className={styles.terms__text}>
                  <u>Disputes</u>. Except as otherwise set forth in these Terms,
                  you agree that any dispute between you and Volterra arising
                  out of or relating to these Terms or the Site and/or Content
                  (or any part thereof) (collectively, “<b>Disputes</b>”) shall
                  be governed by the provisions set forth in this Section.
                </p>
                <p className={styles.terms__text}>
                  <u>Informal Resolution</u>. Before resorting to formal dispute
                  resolution in accordance with this Section, you agree to first
                  contact us directly at{" "}
                  <a
                    aria-label="Mail marketing@volterra.io"
                    className={styles.terms__link}
                    href="mailto:marketing@volterra.io">
                    marketing@volterra.io
                  </a>{" "}
                  to seek an informal resolution to any Dispute. In the event a
                  Dispute is not resolved within thirty (30) days after
                  submission, you or Volterra may institute arbitration in
                  accordance with the procedures set forth in this Section.
                </p>
                <p className={styles.terms__text}>
                  <u>Dispute Resolution</u>. Any and all Disputes that cannot be
                  resolved through informal resolution as set forth above shall
                  be resolved exclusively through final, binding and
                  confidential arbitration and shall take place in Santa Clara,
                  California unless otherwise mutually agreed to by the parties.
                  The arbitration shall be administered by the American
                  Arbitration Association (AAA) under the arbitration rules in
                  effect at the time such dispute is submitted (the “
                  <b>Rules</b>”), provided that the arbitrator and the parties
                  shall comply with the following: (i) the arbitration shall be
                  conducted by telephone, online and/or be solely based on
                  written submissions, the specific manner shall be chosen by
                  the party initiating the arbitration; (ii) the arbitration
                  shall not involve any personal appearance by the parties or
                  witnesses unless otherwise mutually agreed by the parties; and
                  (iii) any judgment on the award rendered by the arbitrator
                  shall be binding, final, and confidential, and may be entered
                  in any court of competent jurisdiction.
                </p>
                <p className={styles.terms__text}>
                  <u>Opting-Out of Arbitration</u>. YOU MAY OPT-OUT OF THE
                  AGREEMENT TO ARBITRATE BY PROVIDING VOLTERRA WRITTEN NOTICE
                  WITHIN THIRTY (30) DAYS OF FIRST ACCEPTING THESE TERMS. YOUR
                  NOTICE MUST INCLUDE: (I) YOUR FULL NAME (FIRST AND LAST); (II)
                  YOUR EMAIL ADDRESS OR OTHER PREFERRED METHOD OF CONTACT; AND
                  (III) A CLEAR STATEMENT THAT YOU DECLINE THIS AGREEMENT TO
                  ARBITRATE.
                </p>
                <p className={styles.terms__text}>
                  <u>Exception to Arbitration</u>. Notwithstanding anything in
                  these Terms to the contrary to the extent you have in any
                  manner violated or threatened to violate any of Volterra’s
                  intellectual property rights, Volterra may seek injunctive or
                  other appropriate relief in any state or federal court with
                  competent jurisdiction in any country, including in the State
                  of California, United States of America, without first
                  engaging in arbitration or the informal dispute process set
                  forth in this Section, and you hereby consent to the personal
                  jurisdiction and exclusive venue in such courts.
                </p>
                <p className={styles.terms__text}>
                  <u>No Class Actions</u>. YOU MAY ONLY RESOLVE DISPUTES WITH
                  VOLTERRA ON AN INDIVIDUAL BASIS, AND MAY NOT BRING A CLAIM AS
                  A PLAINTIFF OR A CLASS MEMBER IN A CLASS, CONSOLIDATED, OR
                  REPRESENTATIVE ACTION. CLASS ARBITRATIONS, CLASS ACTIONS,
                  PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER
                  ARBITRATIONS AREN'T ALLOWED UNDER THESE TERMS.
                </p>
                <p className={styles.terms__text}>
                  <u>Venue</u>. In the event that the agreement to arbitrate is
                  found not to apply to you or your claim, you and Volterra
                  agree that any judicial proceeding will be brought in the
                  federal or state courts of Santa Clara County, California, and
                  the parties consent to venue and personal jurisdiction in such
                  courts.
                </p>
                <p className={styles.terms__text}>
                  <u>Time Limitation to Bring Claims</u>. Notwithstanding any
                  statute or law to the contrary, any claim or cause of action
                  arising out of or related to your use of the Site and/or
                  Content must be filed within one (1) year after such claim or
                  cause of action arose, otherwise that claim or cause of action
                  will be barred forever.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_GENERAL]}
                id="general">
                <h2 className={styles.terms__title}>14. GENERAL</h2>
                <p className={styles.terms__text}>
                  <u>Entire Agreement</u> <br />
                  The Terms constitute the entire agreement between you and
                  Volterra, and supersedes all prior oral or written agreements
                  or communications with regard to the subject matter described
                  herein. Neither the rights nor the obligations arising under
                  these Terms are assignable by you, and any such attempted
                  assignment or transfer shall be void and without effect. The
                  official text of these Terms (and any notice submitted
                  hereunder) will be in English. The parties acknowledge that
                  they require that these Terms be drawn up in the English
                  language only. In the event of any dispute concerning the
                  construction or meaning of these Terms, reference will be made
                  only to these Terms as written in English and not to any
                  translation into another language.
                </p>
                <p className={styles.terms__text}>
                  <u>Waiver</u> <br />
                  You agree that if Volterra does not exercise or enforce any
                  legal right or remedy which is contained in the Terms (or
                  which Volterra has the benefit of under any applicable law),
                  this will not be taken to be a formal waiver of Volterra’s
                  rights and that those rights or remedies will still be
                  available to Volterra. No waiver by either party of any breach
                  of any provision hereof shall be deemed a waiver of any
                  subsequent or prior breach of the same or any other provision.
                </p>
                <p className={styles.terms__text}>
                  <u>Severability</u> <br />
                  If any court of law, having the jurisdiction to decide on this
                  matter, rules that any provision of these Terms is invalid,
                  then that provision will be removed from the Terms without
                  affecting the rest of the Terms, which will remain in full
                  force and effect.
                </p>
              </div>

              <div
                className={
                  styles.terms__section +
                  " " +
                  (showAnnouncement ? styles.terms__sectionIndent : "")
                }
                ref={refs[TERMS_QUESTIONS]}
                id="questions">
                <h2 className={styles.terms__title}>15. QUESTIONS</h2>
                <p className={styles.terms__text}>
                  If you have any questions regarding these Terms, please send
                  an email to{" "}
                  <a
                    aria-label="Mail marketing@volterra.io"
                    className={styles.terms__link}
                    href="mailto:marketing@volterra.io">
                    marketing@volterra.io
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withAppContext(TermsPage)
